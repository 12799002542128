import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "./service/instance";
import { store } from '../index'
import Swal from "sweetalert2";

interface IFormGroup {
    isLoadingOcspConfig: boolean;
    isLoadingEvent: boolean;
    dataOcspConfig: any;
    editDataOcspConfig: any;
    optSubjectName: any;
    optIssuerSubjectName: any;
    totalConfig: any;
    msgSccsFGr: string;
    eventFlag: boolean;
  }

const initialState: IFormGroup = {
    isLoadingOcspConfig: false,
    isLoadingEvent: false,
    dataOcspConfig: [],
    editDataOcspConfig: {},
    optSubjectName: [],
    optIssuerSubjectName: [],
    totalConfig: 0,
    msgSccsFGr: "",
    eventFlag: false,
  };

export const getAllOcspList = createAsyncThunk<any, any, { rejectValue: any }>(
    "ocsp-configure/list",
    async (data, { rejectWithValue }) => {
      try {
        const resp: any = await instance.get(`/ocsp/ocsp-configure`, {
          params: data,
        });
        const responseData = resp.data.data.data
        // console.log("responseData: ", responseData)
        let totalRecord = resp.data.data.total_records;
        return { responseData, totalRecord };
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const getOcspById = createAsyncThunk<any, any, { rejectValue: any }>(
    "ocsp-configure/item",
    async (id, { rejectWithValue }) => {
      try {
        const response: any = await instance.get(`/ocsp/ocsp-configure/${id}`);
        // console.log("getOcspById: ", response)
        const responseData = response.data.data
        return responseData;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const getAllCertificates = createAsyncThunk<any, any, { rejectValue: any }>(
    "certificates/list",
    async ({ rejectWithValue }) => {
      try {
        const response: any = await instance.get(`/certificates`);
        //console.log("getAllCertificates: ", response.data.data.data)
        const responseData = response.data.data.data
        const filteredData = responseData.filter((fi: { certificate_status: string; }) => fi.certificate_status == "active")
        const optSubjectName = filteredData.map((item: any) => (
          {
            label: item.certificate_subject_name,
            value: item.certificate_serial_number,
          }
        ))
        const optIssuerSubjectName = filteredData.map((item: any) => (
          {
            label: item.certificate_issuer_subject_name,
            value: item.certificate_serial_number,
          }
        ))
        const payloadData = {
          optSubjectName: optSubjectName,
          optIssuerSubjectName: optIssuerSubjectName
        }
        //console.log("payloadData: ", payloadData)
        return payloadData;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const getCertificateDropdown = createAsyncThunk<any, any, { rejectValue: any }>(
    "certificates/list-dropdown",
    async ({ rejectWithValue }) => {
      try {
        const response: any = await instance.get(`/dropdown/certificates`);
        //console.log("getCertificateDropdown: ", response)
        const responseData = response.data.data
        const options = responseData.map((item: any) => (
          {
            label: item.certificate_subject_name,
            value: item.certificate_serial_number,
          }
        ))
        const payloadData = {
          optSubjectName: options,
          optIssuerSubjectName: options
        }
        //console.log("payloadData: ", payloadData)
        return payloadData;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const createOcspConfiguration = createAsyncThunk<any, any, { rejectValue: any }>(
    "ocsp-configure/create",
    async (data, { rejectWithValue }) => {
      console.log("CREATE CONFIG DATA: ", data)
      try {
        const resp: any = await instance.post(`/ocsp/ocsp-configure`, data);
        console.log("CREATE CONFIG: ", resp)
        return resp.data;
      } catch (err) {
        console.log("CREATE CONFIG ERR: ", err)
        throw rejectWithValue(err);
      }
    }
  );

  export const updateOcspConfiguration = createAsyncThunk<any, any, { rejectValue: any }>(
    "ocsp-configure/update",
    async (data, { rejectWithValue }) => {
      try {
        const resp = await instance.put(`/ocsp/ocsp-configure/${data.uid}`, data);
        //console.log("UPDATE CONFIG: ", resp)
        return resp.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const deleteOcspConfiguration = createAsyncThunk<any, any, { rejectValue: any }>(
  "ocsp-configure/delete",
    async (data, { rejectWithValue }) => {
      try {
        const resp = await instance.put(`/ocsp/ocsp-configure/${data.uid}`, data);
        return resp.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const satpasDevice = createSlice({
    name: "satpasDevice",
    initialState,
    reducers: {
      setEventFlag: (state) => {
        state.eventFlag = true;
      },
      removeEventFlag: (state) => {
        state.eventFlag = false;
      },
    },
    extraReducers(builder) {
        builder

        // REGION: GET OCSP ALL
        .addCase(getAllOcspList.pending, (state) => {
            state.isLoadingOcspConfig = true;
          })
        .addCase(getAllOcspList.fulfilled, (state, action) => {
          state.isLoadingOcspConfig = false;
          const { responseData, totalRecord } = action.payload
          state.dataOcspConfig = responseData
          state.totalConfig = totalRecord
        })
        .addCase(getAllOcspList.rejected, (state) => {
          state.isLoadingOcspConfig = false;
          state.dataOcspConfig = []
          state.totalConfig = 0
        })

        // REGION: GET OCSP BY ID
        .addCase(getOcspById.pending, (state) => {
          state.isLoadingOcspConfig = true;
        })
        .addCase(getOcspById.fulfilled, (state, action) => {
          state.isLoadingOcspConfig = false;
          state.editDataOcspConfig = action.payload
        })
        .addCase(getOcspById.rejected, (state) => {
          state.isLoadingOcspConfig = false;
          state.editDataOcspConfig = {}
        })

        // REGION: GET CERTIFCATES
        // CURRENTLY NOT USED
        .addCase(getAllCertificates.fulfilled, (state, action) => {
          
        })

        // REGION: GET CERTIFCATES DROPDOWN
        .addCase(getCertificateDropdown.fulfilled, (state, action) => {
          state.optSubjectName = action.payload.optSubjectName;
          state.optIssuerSubjectName = action.payload.optIssuerSubjectName;
        })

        // REGION: CREATE OCSP
        .addCase(createOcspConfiguration.pending, (state) => {
          state.isLoadingEvent = true;
          state.eventFlag = false
        })
        .addCase(createOcspConfiguration.fulfilled, (state) => {
          state.isLoadingEvent = false;
          Swal.fire("Configuration Created!", "Your configuration has been created", "success").then(() => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(createOcspConfiguration.rejected, (state) => {
          state.isLoadingEvent = false;
          Swal.fire("Create Configuration Error!", "Can not create your configuration, Please try again", "error").then(() => {
            store.dispatch(removeEventFlag())
          });
        })
        
        // REGION: UPDATE OCSP
        .addCase(updateOcspConfiguration.pending, (state) => {
          state.isLoadingEvent = true;
          state.eventFlag = false
        })
        .addCase(updateOcspConfiguration.fulfilled, (state, dispatch) => {
          state.isLoadingEvent = false;
          Swal.fire("Configuration Updated!", "Your configuration has been updated", "success").then(() => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(updateOcspConfiguration.rejected, (state) => {
          state.isLoadingEvent = false;
          Swal.fire("Update Configuration Error!", "Can not update your configuration, Please try again", "error").then(() => {
            store.dispatch(removeEventFlag())
          });
        })
        
        // REGION: DELETE OCSP
        .addCase(deleteOcspConfiguration.pending, (state) => {
          state.isLoadingEvent = true;
          state.eventFlag = false
        })
        .addCase(deleteOcspConfiguration.fulfilled, (state) => {
          state.isLoadingEvent = false;
          Swal.fire("Configuration Deleted!", "Your configuration has been deleted", "success").then(() => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(deleteOcspConfiguration.rejected, (state) => {
          state.isLoadingEvent = false;
          Swal.fire("Delete Configuration Error!", "Can not delete your configuration, Please try again", "error").then(() => {
            store.dispatch(removeEventFlag())
          });
        })
    }
  });
  
  export const { setEventFlag, removeEventFlag } = satpasDevice.actions;
  export default satpasDevice.reducer;
  