import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown,
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import satpasDevice from "../../utils/validation/satpasDevice";

const KeyField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("detail");

  // const {
  //   editDataOcspConfig,
  //   isLoadingOcspConfig,
  //   isLoadingEvent,
  //   eventFlag
  // } = useAppSelector((state: RootState)=> state.satpasDevice)
  // const {
  //   uc_ocsp_config_uid,
  //  } = editDataOcspConfig

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    keyName: string;
    keyType: string;
    keyLength: string;
    curve: string;
    keyId: string;
    publicKey: string;
    createdDate: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(satpasDevice),
    defaultValues: {
      keyName: "",
      keyType: "",
      keyLength: "",
      curve: "",
      keyId: "",
      publicKey: "",
      createdDate: "",
    },
  });

  // REGION: SET FORM DATA
  // useEffect(() => {
  //   if (editFgroup) {
  //     reset({
  //       uid: uc_ocsp_config_uid,
  //       encryptedString: "",
  //       publicKey: "",
  //     });
  //   } else {
  //     reset();
  //   }
  // }, [ editDataOcspConfig ]);

  // REGION: CHANGE STATE
  useEffect(() => {
    // reset({
    //   keyName: state.keyName,
    //   keyType: state.keyType,
    //   keyLength: state.length,
    //   keyId: state.kid,
    //   createdDate: state.createdAt,
    // });
    setValue("keyName", state.keyName);
    setValue("keyType", state.keyType);
    setValue("keyLength", state.length);
    setValue("keyId", state.kid);
    setValue("createdDate", state.createdAt);
    if (state.length === 0) {
      setValue("curve", state.curved);
    }
    console.log("state hahah ==> ", state);
  }, [state]);

  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if(eventFlag) {
  //     navigate("/key")
  //     dispatch(removeEventFlag())
  //   }
  // }, [eventFlag]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {/* {editFgroup ? "Update Key" : "Create Key"} */}
          Detail Key
        </Typography>
        <InputForm
          name="keyName"
          label="Key Name"
          placeholder="keyName"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <InputForm
          name="keyType"
          label="Key Type"
          placeholder="Phone Number"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {state.length !== 0 ? (
          <InputForm
            name="keyLength"
            label="Key Length"
            placeholder="persSuffix"
            disabled={editFgroup}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
            type="number"
          />
        ) : (
          <InputForm
            name="curve"
            label="Curve"
            placeholder="persSuffix"
            disabled={editFgroup}
            isLoading={false}
            control={control}
            errors={errors}
            maxLength={500}
            required
          />
        )}
        <InputForm
          name="keyId"
          label="Key ID"
          placeholder="Phone Number"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="createdDate"
          label="Created Date"
          placeholder="Phone Number"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/key")}
          >
            Back
          </Button>
          {/* { editFgroup &&
          <Button variant="contained" type="submit">
            Update
          </Button>
        }
        { !editFgroup &&
          <Button variant="contained" type="submit">
            Submit
          </Button>
        } */}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default KeyField;
