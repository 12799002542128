import { createSlice } from "@reduxjs/toolkit";
import { satpasDeviceApiSlice } from "./service/satpasDeviceApiSlice";

interface IFormGroup {
    isLoadingOcspConfig: boolean;
    isLoadingEvent: boolean;
    dataOcspConfig: any;
    editDataOcspConfig: any;
    optSubjectName: any;
    optIssuerSubjectName: any;
    totalConfig: any;
    msgSccsFGr: string;
    eventFlag: boolean;
  }

const initialState: IFormGroup = {
    isLoadingOcspConfig: false,
    isLoadingEvent: false,
    dataOcspConfig: [],
    editDataOcspConfig: {},
    optSubjectName: [],
    optIssuerSubjectName: [],
    totalConfig: 0,
    msgSccsFGr: "",
    eventFlag: false,
  };

const satpasDeviceSlice = createSlice({
    name: 'satpasDevice',
    initialState,
    reducers: {},
})


// export const {} = satpasDeviceSlice.actions;
export const satpasDeviceReducer = satpasDeviceSlice.reducer