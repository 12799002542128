import IRoutes from "../interfaces/IRoutes";
import SatpasDevice from "../pages/key/Key";
import SatpasDeviceField from "../pages/key/KeyField";

const SatpasDeviceRoute: Array<IRoutes> = [
  {
    element: <SatpasDevice />,
    path: "/key",
  },
  {
    element: <SatpasDeviceField />,
    path: "/key/create",
  },
  {
    element: <SatpasDeviceField />,
    path: "/key/detail",
  }
];

export { SatpasDeviceRoute };
