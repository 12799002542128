import IRoutes from "../interfaces/IRoutes";
import GenerateKeyField from "../pages/generateKey/GenerateKey";

const LivenessDeviceRoute: Array<IRoutes> = [
  {
    element: <GenerateKeyField />,
    path: "/create-key",
  },
];

export { LivenessDeviceRoute };
