import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown,
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import licenseServer from "../../utils/validation/generateKey";
import { usePostGenerateKeyMutation } from "../../store/feature/service/generateKey";
import Swal from "sweetalert2";
import { useGetKeyQuery } from "../../store/feature/service/keyApiSlice";

const GenerateKeyField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;
  const [lengthOpt, setLenghtOpt] = useState<any>([]);

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    keyName: string;
    keyType: string;
    keyLength: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(licenseServer),
    defaultValues: {
      uid: "",
      keyName: "",
      keyType: "",
      keyLength: "",
    },
  });

  const [
    PostGenerateKey,
    { data: generateData, isLoading, isSuccess, isError, status, error },
  ] = usePostGenerateKeyMutation();

  const keyTypeDropdown = [
    {
      label: "RSA",
      value: "RSA",
    },
    {
      label: "AES",
      value: "AES",
    },
    {
      label: "ECDSA",
      value: "ECDSA",
    },
    {
      label: "HMACSHA256",
      value: "HMACSHA256",
    },
    {
      label: "HMACSHA384",
      value: "HMACSHA384",
    },
    {
      label: "HMACSHA512",
      value: "HMACSHA512",
    },
  ];
  const keyTypeRSA = [
    {
      label: "1024",
      value: "1024",
    },
    {
      label: "2048",
      value: "2048",
    },
    {
      label: "4096",
      value: "4096",
    },
  ];

  const keyTypeECDSA = [
    {
      label: "P-256",
      value: "P-256",
    },
    {
      label: "P-384",
      value: "P-384",
    },
    {
      label: "P-521",
      value: "P-521",
    },
  ];

  const keyTypeAES = [
    {
      label: "128",
      value: "128",
    },
    {
      label: "192",
      value: "192",
    },
    {
      label: "256",
      value: "256",
    },
  ];

  const keyTypeMix = [
    {
      label: "8 Bit",
      value: "8",
    },
    {
      label: "16 Bit",
      value: "16",
    },
    {
      label: "32 Bit",
      value: "32",
    },
    {
      label: "128 Bit",
      value: "128",
    },
    {
      label: "256 Bit",
      value: "256",
    },
    {
      label: "512 Bit",
      value: "512",
    },
    {
      label: "1024 Bit",
      value: "1024",
    },
    {
      label: "2048 Bit",
      value: "2048",
    },
  ];

  if (isSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Create",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Create Generate Key",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => navigate("/key"));
  }

  if (isError) {
    Swal.fire({
      icon: "error",
      title: "Error Create",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Create Generate Key",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  const onChangeKeyType = (e: any) => {
    if (e.label === "RSA") {
      setLenghtOpt(keyTypeRSA);
    } else if (e.label === "AES") {
      setLenghtOpt(keyTypeAES);
    } else if (e.label === "ECDSA") {
      setLenghtOpt(keyTypeECDSA);
    } else {
      setLenghtOpt(keyTypeMix);
    }
  };

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    console.log("e ===> ", e);
    let dataPost = {
      keytype: e.keyType,
      length: Number(e.keyLength),
      ident: e.keyName,
    };
    console.log("keyname ===> ", e.keyType);
    let dataEcdsa = {
      keytype: e.keyType,
      curve: e.keyLength,
      appname: "simple",
      ident: e.keyName,
    };
    PostGenerateKey(e.keyType === "ECDSA" ? dataEcdsa : dataPost);

    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Create Key
        </Typography>
        <InputForm
          name="keyName"
          label="Key Name"
          placeholder="Key Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <SelectFormm
          name="keyType"
          label="Key Type"
          defaultValue={0}
          options={keyTypeDropdown}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
          onChangeForm={onChangeKeyType}
        />
        <SelectFormm
          name="keyLength"
          label={watch("keyType") === "ECDSA" ? "Curved" : "Key Length"}
          defaultValue={0}
          options={lengthOpt}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/key")}
          >
            Cancel
          </Button>
          {editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )}
          {!editFgroup && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default GenerateKeyField;
