import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { makeStyles, styled, useTheme } from "@mui/material/styles";
import { IOpenMainProps } from "../../../interfaces/IUtils";
import { useNavigate } from "react-router-dom";
import { arrSidebar } from "../../../constants/SidebarRouteName";
import { RootState, useAppSelector } from "../../../store";
import { useEffect, useState } from 'react';
import korlantasLogo from "../../../assets/logo/korlantas-logo.png";
import { ISidebar } from "../../../interfaces/ISidebar";
import React from "react";

// Icons 
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sidebar = ({ open, setOpen }: IOpenMainProps) => {
  const drawerWidth = 320;
  const navigate = useNavigate();
  const theme = useTheme();
  const [selected, setSelected] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedChild, setSelectedChild] = useState(-1);
  const [openChild, setOpenChild] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(true);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = () => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = () => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const handleSelectedClick = (path: any,index: number) => {
    setSelected(selected === index ? -1 : index);
    setOpenChild((prev) => !prev);
    // setSelected(index);
    if (path.length) {
      navigate(path);
    } else {
      setSelectedChild(-1);
    }
  };

  const handleSelectedChild = (index: number) => {
    // setSelected(selected === index ? -1 : index);
    setSelectedChild(index);
    setPopoverOpen(true);
    setAnchorEl(null);
  };

  const handleNavigate = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const openPop = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const renderChildren = (children?: ISidebar[]) => {
    if (!children) return null;
    // const filteredData = children.filter(item => tempSidebar.includes(item.key));
    return children.map(
      ({ key, name, path, icon, children: subChildren }, index) => {
        // if (subChildren && !open) {
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => navigate(path)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 4,
                color:
                  selectedChild === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              selected={selectedChild === index}
              onClick={() => handleSelectedChild(index)}
              // style={{ backgroundColor: "#242526" }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        );
      }
    );
  };

  const renderChildrenPopover = (children?: ISidebar[]) => {
    if (!children) return null;
    return children.map(
      ({ key, name, path, icon, children: subChildren }, index) => {
        // if (subChildren && !open) {
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{ display: 'block', bgcolor: theme.palette.primary.main }}
            onClick={() => navigate(path)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 4,
                color:
                  selectedChild === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              selected={selectedChild === index}
              onClick={() => handleSelectedChild(index)}

              // style={{ backgroundColor: "#242526" }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
          // </Popover>
        );
      }
    );
  };

  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(),
          "& .MuiDrawer-paper": openedMixin(),
        }),
        ...(!open && {
          ...closedMixin(),
          "& .MuiDrawer-paper": closedMixin(),
        }),
      }}
      variant="permanent"
    >
      <Box
        sx={{
          backgroundColor: `${theme.palette.primary.main}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
        }}
      >
        {open && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: theme.palette.white.darker }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        )}
      </Box>
      <List>
        {arrSidebar
        .map(({ key, name, path, icon, children }: ISidebar, index) => (
          <React.Fragment key={key}>
          <ListItem
            key={key}
            disablePadding
            sx={{ display: "block" }}
            onClick={handleNavigate}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                color:
                  selected === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              onClick={() => handleSelectedClick(path, index)}
              selected={selected === index}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color:
                    selected === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={name}
                sx={{
                  opacity: open ? 1 : 0,
                }}
              />
               {open &&
                    children &&
                    (openChild ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
            </ListItemButton>
          </ListItem>
          {selected === index && open && renderChildren(children)}
          {selected === index && !open && (
            <Popover
              id="popover"
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              {renderChildrenPopover(children)}
            </Popover>
          )}
          </React.Fragment>
        ))}
      </List>
    </MuiDrawer>
  );
};

export default Sidebar;
