import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import { getAllOcspList, removeEventFlag } from "../../store/feature/satpasDevice";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import LicenseServerDetailTable from "../../components/molecules/licenseServerDetail/LicenseServerDetailTable";

const LicenseServerDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const idParam = useParams();
  const { dataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector((state: RootState)=> state.satpasDevice)

  // REGION: DISPATCH OCSP ALL
  useEffect(()=> {
    // dispatch(getAllOcspList(params))
  },[params])


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      // dispatch(getAllOcspList(params))x`
      dispatch(removeEventFlag())
    }
  }, [ eventFlag ]);

  
  // REGION: RETURN TSX
  return (
    <ListDataSkeleton isLoading={isLoadingOcspConfig} isLoadingEvent={isLoadingEvent} titleWidth={'300px'}>
    <Box>
      <Typography variant="h3" fontFamily='Open Sans' sx={{marginY: '20px'}}>
      Detail License Server
      </Typography>
      <Button variant="contained" color="primary" onClick={()=> navigate(`/license-server/license-detail/${idParam.id}/create`)} sx={{marginBottom: '18px'}}>
          <AddIcon /> Create
        </Button>
      {/* <LicenseServerDetailSearch
        params={params}
        setParams={setParams}
      /> */}
      <LicenseServerDetailTable
        data={dataOcspConfig}
        params={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
    </Box>
    </ListDataSkeleton>
  );
};

export default LicenseServerDetail;