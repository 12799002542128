import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";

const schema = yup.object({
  title: yup.string().required(),
});

const LogResultSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [module, setModule] = useState("");
  const [functionName, setFunctionName] = useState("");
  const [severity, setSeverity] = useState("");
  const [type, setType] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    module: string;
    functionName: string;
    severity: string;
    type: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      module: "",
      functionName: "",
      severity: "",
      type: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Low",
      value: "LOW",
    },
    {
      label: "Medium",
      value: "MEDIUM",
    },
    {
      label: "High",
      value: "HIGH",
    },
  ];

  const handleModule = (e: string) => { setModule(e); };
  const handleFunctionNameSelect = (e: any) => { setFunctionName(e); };
  const handleSeveritySelect = (e: any) => { setSeverity(e.value); }; 
  const handleTypeSelect = (e: any) => { setType(e); };
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setDateStart(NewDate);
  };
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setDateEnd(NewDate);
  };

  useDebouncedEffect(() => setSearchFilterDebounced(module), [module], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(functionName), [functionName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(severity), [severity], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(type), [type], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      param_filter_device_id: module,
      param_filter_functionName: functionName,
      param_filter_type: type,
      param_filter_status: severity, 
      param_filter_enrolled_start: dateStart,
      param_filter_enrolled_end: dateEnd,
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="module"
          errors={errors}
          control={control}
          required
          placeholder="Device ID"
          maxLength={100}
          isLoading={false}
          onChange={handleModule}
        />
        <InputForm
          name="function"
          errors={errors}
          control={control}
          required
          placeholder="Hostname"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionNameSelect}
        />
        {/* <InputForm
          name="type"
          errors={errors}
          control={control}
          required
          placeholder="Type"
          maxLength={100}
          isLoading={false}
          onChange={handleTypeSelect}
        /> */}
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateEnd}
        />
        <SelectForm 
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Result"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleSeveritySelect}
        />
      </Box>
    </Box>
  );
};

export default LogResultSearch;
