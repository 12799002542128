import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../utils/storage';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_ADMIN}/`,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (process.env.NODE_ENV === 'development') {
        headers.set('x-mock-response-code', '200');
      }
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });


  const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);
    return result;
  };  

  export const apiAdminSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    tagTypes: [
      'submitLogin',
      'GetDashboardInfo'
    ],
  });
  
  export const { endpoints, reducerPath, reducer, middleware } = apiAdminSlice;
  