import { Button, Grid, Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputForm from "../../components/molecules/forms/inputForm";
import type { RootState } from "../../store";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import LoginSchema from "../../utils/validation/login";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../utils/storage";
import { useSubmitLoginMutation } from "../../store/feature/service/userApiSlice";
import Swal from "sweetalert2";
import {
  MailOutline as MailOutlineIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import indonesiaImg from "../../assets/logo/Indonesia.png";
// import korlantasImg from "../../assets/logo/Indonesia.png";
import autentikaImg from '../../assets/logo/medium.png';
import entrustLogo from "../../assets/logo/entrustWhite.png";
// import { text } from "stream/consumers";
// import PopUpTab from '../popUpTab/PopUpTab';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isShowPass, setIsShowPass] = useState(false);
  const [submitLogin, { error: errorLogin, isLoading, isError }] =
    useSubmitLoginMutation();
  const { accesToken } = useAppSelector((state: RootState) => state.user);

  interface ILogin {
    email: string;
    password: string;
    showPass: boolean;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    reValidateMode: "onChange",
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
      showPass: false,
    },
  });

  const onSubmit = async (data: ILogin) => {
    submitLogin(data);
  };
  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  useEffect(() => {
    if (isError) {
      const dataError: any = errorLogin;
      let message = "Error Login, Please try again";
      message = dataError?.data?.error_description;
      if (
        dataError?.data?.error_description === "Account is not fully set up"
      ) {
        Swal.fire({
          icon: "info",
          title: "Change Password Notification",
          showCancelButton: false,
          confirmButtonText: "Change Password",
          confirmButtonColor: "#051438",
          text: `Password change required. Please enter your new password on the next screen`,
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          window.location.href = "http://10.10.10.92:6001/login";
        });
      } else {
        try {
          // console.log('data error ===> ', dataError?.data?.error_description === "Account is not fully set up")
          if (message == "Invalid user credentials") {
            message =
              "Incorrect username/password combination. Please check and try again";
          }
        } catch (e) {}
        Swal.fire({
          icon: "error",
          title: "Error Login",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: message,
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      }
    }
  }, [isError]);

  useEffect(() => {
    if (accesToken) {
      setToken(accesToken);
      navigate("/", { replace: true });
    }
  }, [accesToken]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#051438", // Change this to your desired color
      },
    },
  });

  return (
    <div
      style={{
        backgroundImage: `url(${indonesiaImg})`,
        backgroundSize: "cover",
        backgroundColor: "#051438",
        height: "auto",
        maxWidth: "100%",
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "105vh",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
          }}
        >
          <Stack
            style={{
              display: "grid",
              placeItems: "center", // Centers both horizontally and vertically
            }}
          >
            <Box
              bgcolor="#FFFFFF"
              borderRadius={"50%"}
              boxShadow={3}
              width={"100px"}
              height={"100px"}
              textAlign="center"
              marginBottom={"-50px"}
              zIndex={1000}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={autentikaImg} height={"75px"} width={"75px"} />
            </Box>
            <Box
              pt={9}
              pb={2}
              pl={5}
              pr={5}
              bgcolor="#FFFFFF"
              borderRadius={4}
              boxShadow={3}
              maxWidth="500px"
              width="100%"
              textAlign="center"
            >
              <Grid
                container
                direction="column"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    Welcome to WSOP
                  </Typography>
                </Grid>
                <Grid item>
                  <InputForm
                    name="Email"
                    errors={errors}
                    control={control}
                    required
                    placeholder="Username / Email"
                    maxLength={100}
                    isLoading={false}
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    showStartAdornment
                    startAdornment={<MailOutlineIcon />}
                    sxFC={{ marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item>
                  <InputForm
                    name="Password"
                    errors={errors}
                    control={control}
                    type={isShowPass ? "text" : "password"}
                    placeholder="Password"
                    maxLength={127}
                    showEndAdornment
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    endAdornment={endAdornment}
                    onClickEndAdornment={toggleShowPassword}
                    required
                    showStartAdornment
                    startAdornment={<LockIcon />}
                    sxFC={{ marginBottom: "1.5rem" }}
                  />
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        Login
                      </LoadingButton>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ borderRadius: "12px", textTransform: "none" }}
                        >
                          Login
                        </Button>
                        {/* <PopUpTab /> */}
                      </>
                    </ThemeProvider>
                  )}
                </Grid>
                <Grid item sx={{ marginTop: "20px" }}>
                  <img src={entrustLogo} height={"50px"} width={"180px"} />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
