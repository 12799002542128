import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import KeySearch from '../../components/molecules/key/KeySearch';
import KeyTable from '../../components/molecules/key/KeyTable';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import { useGetKeyQuery } from '../../store/feature/service/keyApiSlice';
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const Key = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // const data: any = []
  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data, isLoading } = useGetKeyQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(data){
      console.log('data baru ===> ', data)
    }
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      // isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Key
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/create-key')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button>
        {/* <KeySearch params={params} setParams={setParams} /> */}
        <KeyTable
          data={data !== undefined ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default Key;
