import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from '../pagination/Pagination';
import Swal from 'sweetalert2';

const LicenseServerTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/license-server/license-detail/${params.row.uid}`);
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'hostname',
      headerName: 'Host Name',
      flex: 2,
      minWidth: 250,
    },
    {
      field: 'hostIp',
      headerName: 'Host IP',
      flex: 2,
      minWidth: 250,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === 'active' ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  const rows = data?.map((row: any) => ({
    uid: row.uc_ocsp_config_uid,
    id: row.uc_ocsp_config_id,
    hostname: 'utkor-a',
    hostIp: 'http://115.85.79.243:13001',
    status: 'active',
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalConfig}
      />
    </>
  );
};

export default LicenseServerTable;
