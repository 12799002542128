import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminManagementSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    roles: string;
    createdFrom: string;
    createdTo: string;
    lastLogin: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: "",
      createdFrom: "",
      createdTo: "",
      lastLogin: "",
    },
  });

  const optSelect = [
    {
      label: "SUPERADMIN",
      value: "SUPERADMIN",
    },
    {
      label: "ADMINISTRATOR",
      value: "ADMINISTRATOR",
    },
    {
      label: "OPERATOR",
      value: "OPERATOR",
    },
  ];

  const handleFirstName = (e: string) => { setFirstName(e); };
  const handleLastNameSelect = (e: any) => { setLastName(e); };
  const handleEmailSelect = (e: any) => { setEmail(e); };
  const handleRolesSelect = (e: any) => { setRoles(e.value); }; 
  const handleCreatedFrom = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setCreatedFrom(NewDate);
  };
  const handleCreatedTo = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setCreatedTo(NewDate);
  };
  const handleLastLogin = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setLastLogin(NewDate);
  };

  useDebouncedEffect(() => setSearchFilterDebounced(firstName), [firstName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(lastName), [lastName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(email), [email], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(roles), [roles], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdFrom), [createdFrom], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdTo), [createdTo], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(lastLogin), [lastLogin], 500); 

  useEffect(() => {
    setParams({
      ...params,
      param_filter_device_id: firstName,
      param_filter_lastName: lastName,
      param_filter_email: email,
      param_filter_roles: roles, 
      param_filter_enrolled_start: createdFrom,
      param_filter_enrolled_end: createdTo,
      param_filter_last_login: lastLogin,
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="firstName"
          errors={errors}
          control={control}
          required
          placeholder="Firstname"
          maxLength={100}
          isLoading={false}
          onChange={handleFirstName}
        />
        <InputForm
          name="lastName"
          errors={errors}
          control={control}
          required
          placeholder="Lastname"
          maxLength={100}
          isLoading={false}
          onChange={handleLastNameSelect}
        />
        <InputForm
          name="email"
          errors={errors}
          control={control}
          required
          placeholder="Email"
          maxLength={100}
          isLoading={false}
          onChange={handleEmailSelect}
        />
        <SelectForm 
          name="roles"
          defaultValue={1}
          options={optSelect}
          placeholder="Roles"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleRolesSelect}
        />
        <DatePickerForm
          name="createdFrom"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created From"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedFrom}
        />
        <DatePickerForm
          name="createdTo"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created To"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedTo}
        />
        <DatePickerForm
          name="lastLogin"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Last Login"
          disabled={false}
          isLoading={false}
          required
          onChange={handleLastLogin}
        />
      </Box>
    </Box>
  );
};

export default AdminManagementSearch;
