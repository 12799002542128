// import { instance, instanceEndUser } from "./service/instance";
import { apiPasswordlice } from '../api/apiPasswordSlice';

  export const ChangePasswordApiSlice = apiPasswordlice.injectEndpoints({
    endpoints: builder => ({
        // getLicenseServerList: builder.query<any, any>({
        //     query: params => {
        //         return {
        //             url: `/ocsp/ocsp-configure`,
        //             params: params
        //         }
        //     },
        //     providesTags:['GetLicenseServerList'],
        // })
        updatePassword2: builder.mutation<any, any>({
            query: (body) => {
              //   const encryptedOldPassword = encrypt(oldPassword);
              //   const encryptedNewPassword = encrypt(newPassword);
              return {
                url: `/user/change-password`,
                method: "PUT",
                body,
              };
            },
            invalidatesTags: ["UpdatePassword"],
          }),
  })
})

export const {
    useUpdatePassword2Mutation
} = ChangePasswordApiSlice;