import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DnsIcon from '@mui/icons-material/Dns';
import DvrIcon from '@mui/icons-material/Dvr';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  },
  { 
    key: 'key',
    name: 'Key',
    path: '/key',
    icon: <FeaturedPlayListIcon />,
  },
  { 
    key: 'import-key',
    name: 'Import Public Key',
    path: '/import-key',
    icon: <DnsIcon />,
  },
  // { 
  //   key: 'log',
  //   name: 'Log',
  //   path: '',
  //   icon: <DvrIcon />,
  //   children: [
  //     { 
  //       key: 'log-events',
  //       name: 'Log Events',
  //       path: '/log-events',
  //       icon: <DvrIcon />,
  //     },
  //     { 
  //       key: 'log-result',
  //       name: 'Log Result',
  //       path: '/log-result',
  //       icon: <DvrIcon />,
  //     },
  //   ]
  // },
  // { 
  //   key: 'admin-management',
  //   name: 'Admin Management',
  //   path: '/admin-management',
  //   icon: <ManageAccountsIcon />,
  // },
  // { 
  //   key: 'generate-key',
  //   name: 'Generate Key',
  //   path: '/generate-key',
  //   icon: <DevicesOtherIcon />,
  // },
  { 
    key: 'log-events',
    name: 'Log Events',
    path: '/log-events',
    icon: <DvrIcon />,
  },
];
