import { Box } from "@mui/system";
import "chart.js/auto";
import { Button, Grid, Paper, Typography } from "@mui/material";
import DailyStatics from "../../components/molecules/dashboard/DailyStatics";
import HeaderDashboard from "./HeaderDashboard";
import DashboardGrid from "./DashboardGrid";
import {
  useApiKeyMutation,
  useGetDashboardInfoMutation,
} from "../../store/feature/service/dashboard";
import { useEffect, useMemo, useState } from "react";
import entrustLogo from "../../assets/logo/entrustWhite.png";
import dayjs from "dayjs";
import { InputForm } from "../../components/molecules/forms";
import { useForm } from "react-hook-form";
import {
  MailOutline as MailOutlineIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";

const Dashboard = () => {
  const [isShowPass, setIsShowPass] = useState(false);
  const currentDate = dayjs();
  const currentDateOnly = dayjs().format("YYYY-MM-DD");
  const [getDashboardInfo, { data }] = useGetDashboardInfoMutation();
  const [
    apiKeyMut,
    { isSuccess: successApiKey, isError: isErrorApiKey, data: dataApiKey },
  ] = useApiKeyMutation();

  const dashboardData = data !== undefined ? data?.groups[0] : "";

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    apiKey: string;
  }>({
    reValidateMode: "onChange",
    defaultValues: {
      apiKey: "",
    },
  });

  useEffect(() => {
    if (successApiKey) {
      console.log("data.groups ===> ", dataApiKey.apiKey);
      setValue("apiKey", dataApiKey.apiKey);
    }
  }, [successApiKey]);

  useEffect(() => {
    getDashboardInfo({});
  }, []);

  const handleButtonGenerate = () => {
    console.log("roooong ===> ");
    apiKeyMut({});
  };

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const onCopyID = (e: any) => {
    // e.stopPropagation(); // don't select this row after clicking

    const copyText = watch("apiKey");

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Succes Copy API Key",
            showConfirmButton: false,
            text: "Succes Copy API Key to Clipboard",
            timer: 1000,
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          Swal.fire({
            icon: "error",
            title: "Failed to Copy API Key",
            text: "An error occurred while copying the API Key to the clipboard.",
          });
        });
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        Swal.fire({
          icon: "success",
          title: "Succes Copy API Key",
          showConfirmButton: false,
          text: "Succes Copy API Key to Clipboard",
          timer: 1000,
        });
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        Swal.fire({
          icon: "error",
          title: "Failed to Copy API Key",
          text: "An error occurred while copying the API Key to the clipboard.",
        });
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
        padding: "40px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Typography variant="h2bold" sx={{ mb: 3 }}>
            Welcome, {dashboardData.name}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant="h5bold" sx={{ mb: 2 }}>
            {currentDateOnly}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant="body14Semibold">
            Protection domain and key Information <br />
          </Typography>
        </Grid>
        <Grid item lg={2.5}>
          <Typography variant="body14Semibold">
            Type
            <br />
            Group ID
            <br />
            Protected Domain <br />
            Softcard Name <br />
            Keys Link
            <br />
          </Typography>
        </Grid>
        <Grid item lg={9}>
          <Typography variant="body14Semibold">
            : {dashboardData.type}
            <br />: {dashboardData.groupid}
            <br />: {dashboardData.protection} <br />: {dashboardData.name}{" "}
            <br />: {dashboardData.keys}
            <br />
          </Typography>
        </Grid>
        <Grid
          item
          lg={2.5}
          display={"flex"}
          alignItems={"center"}
          mb={1.5}
          justifyContent={"space-between"}
        >
          <Button variant="contained" onClick={handleButtonGenerate}>
            Generate API Key
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={onCopyID}
            disabled={watch("apiKey").length === 0}
          >
            Copy API Key
          </Button>
        </Grid>
        <Grid item lg={7}>
          <InputForm
            name="apiKey"
            errors={errors}
            control={control}
            required
            type={isShowPass ? "text" : "password"}
            placeholder="Please Hit Generate Api Key Button to Generate Api Key"
            maxLength={100}
            showEndAdornment
            isLoading={false}
            sxEndAdornment={{
              backgroundColor: "inherit",
              border: "none",
              cursor: "pointer",
              width: "4rem",
            }}
            endAdornment={endAdornment}
            onClickEndAdornment={toggleShowPassword}
            sxFC={{ marginBottom: "1.5rem" }}
            disabled
          />
        </Grid>
        <Grid item lg={12}>
          <Typography variant="body12Regular">
            The generated API key will only appear once. Please view the value
            by pressing the eye icon in the API Key text field. Please save the
            generated API key value before it is no longer visible or regenerate
            the API Key.
          </Typography>
        </Grid>
      </Grid>
      {/* <HeaderDashboard />
      <DashboardGrid /> */}
      <img
        src={entrustLogo}
        alt="Description of image"
        style={{
          width: 180,
          height: 50,
          position: "absolute",
          bottom: 0,
          right: 0,
          margin: "10px",
        }}
      />
    </Box>
  );
};

export default Dashboard;
