import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import LicenseServerSearch from '../../components/molecules/licenseServer/LicenseServerSearch';
import LicenseServerTable from '../../components/molecules/licenseServer/LicenseServerTable';
import { useGetLicenseServerListQuery } from '../../store/feature/service/licenseServerApiSlice';

const LicenseServer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataLicenseServer, isLoading } = useGetLicenseServerListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      // dispatch(getAllOcspList(params));
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          License Server
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/license-server/create')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button>
        {/* <LicenseServerSearch
        params={params}
        setParams={setParams}
      /> */}
        <LicenseServerTable
          data={dataLicenseServer !== undefined ? dataLicenseServer?.data?.data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default LicenseServer;
