import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";

const LicenseServerDetailTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector((store: RootState) => store.satpasDevice);

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/license-server/license-detail/${params.row.uid}`);
    };

    // REGION: RETURN TSX
    return (
      <>
      <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
      </Stack>
      </>
    );
  };


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "capacity",
      headerName: "Capacity/Lot",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "addedAt",
      headerName: "Added At",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "addedBy",
      headerName: "Added By",
      flex: 2,
      minWidth: 250,
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   minWidth: 250,
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   renderCell: customCellRender,
    // },
  ];


  // REGION: INIT ROWS
  const rows = data.map((row: any) => ({
    uid: row.uc_ocsp_config_uid,
    id: row.uc_ocsp_config_id,
    capacity: "2,000",
    addedAt: "23 Nov 2022 17:18",
    addedBy: "TEST CODE",
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalConfig} />
    </>
  );
};

export default LicenseServerDetailTable;
