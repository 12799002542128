import { apiSlice } from '../api/apiSlice'

  export const generateKey = apiSlice.injectEndpoints({
    endpoints: builder => ({
        postGenerateKey: builder.mutation<any, any>({
            query: (body) => {
                console.log('body haha ===> ', body)
                return {
                    url: `/key/generate`,
                    method: 'POST',
                    body
                }
            },
            invalidatesTags:['postGenerateKey', 'GetKeysList'],
        })
  })
})

export const {
    usePostGenerateKeyMutation
} = generateKey;