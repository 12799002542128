import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import adminManagement from "../../utils/validation/adminManagement";
import confirmPassword from "../../utils/validation/confirmPassword";

const AdminManagementChangePassword = () => {

  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const {
    editDataOcspConfig,
    optSubjectName,
    isLoadingOcspConfig,
    isLoadingEvent,
    eventFlag
  } = useAppSelector((state: RootState)=> state.satpasDevice)
  const {
    uc_ocsp_config_uid,
   } = editDataOcspConfig
  //  const optStatusSelect = [
  //    {
  //      label: "Active",
  //      value: "Active",
  //    },
  //    {
  //      label: "Inactive",
  //      value: "Inactive",
  //    },
  //  ];

   
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    password: string;
    confirmPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(confirmPassword),
    defaultValues: {
      uid: "",
      password: "",
      confirmPassword: "",
    },
  });


  // REGION: SET FORM DATA
  useEffect(() => {
    reset({
      uid: uc_ocsp_config_uid,
      password: "",
      confirmPassword: "",
    });
  }, [ editDataOcspConfig ]);


  // REGION: DISPATCH GET DATA
  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getOcspById(idParam.id));
    }
    dispatch(getCertificateDropdown({}));
  }, []);


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      navigate("/admin-management")
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);


  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    // const updateData = { }
    // dispatch(updateOcspConfiguration(updateData));
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
    isLoading={isLoadingOcspConfig}
    isLoadingEvent={isLoadingEvent}
    inputCount={6}
    titleWidth={'350px'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        Change Password
      </Typography>

        <InputForm
          name="password"
          label="Password"
          placeholder="Password"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="password"
        />

        <InputForm
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm Password"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="password"
        />

      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/admin-management")}>
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default AdminManagementChangePassword;
