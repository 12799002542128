import { encrypt } from "../../../utils/hash/password";
import { apiAdminSlice } from "../api/apiAdminSlice";
import { ILogin } from "../reducers/userReducer";

export const userApiSlice = apiAdminSlice.injectEndpoints({
    endpoints: builder => ({
        submitLogin: builder.mutation<any, any>({
            query: credentials => {
                const { Email, Password } = credentials;
                const pasEnc = encrypt(Password);
                return ({
                    url: '/login_with_password',
                    method: 'POST',
                    body: {
                        username: Email,
                        password: Password,
                    },
                })
            },
            invalidatesTags:['submitLogin', 'GetDashboardInfo']
        })
    })
})

export const { useSubmitLoginMutation } = userApiSlice