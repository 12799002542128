import IRoutes from "../interfaces/IRoutes";
import LicenseServer from "../pages/licenseServer/LicenseServer";
import LicenseServerDetail from "../pages/licenseServer/LicenseServerDetail";
import LicenseServerDetailField from "../pages/licenseServer/LicenseServerDetailField";
import LicenseServerField from "../pages/licenseServer/LicenseServerField";

const LicenseServerRoute: Array<IRoutes> = [
    {
        element: <LicenseServer />,
        path: "/license-server",
    },
    {
        element: <LicenseServerField />,
        path: "/license-server/create",
    },
    {
        element: <LicenseServerDetail />,
        path: "/license-server/license-detail/:id",
    },
    {
        element: <LicenseServerDetailField />,
        path: "/license-server/license-detail/:id/create",
    },
];

export { LicenseServerRoute };
