import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import LogResultSearch from '../../components/molecules/logResult/LogResultSearch';
import LogResultTable from '../../components/molecules/logResult/LogResultTable';
import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const LogResult = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataOcspConfig } = useGetAllOcspListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      // dispatch(getAllOcspList(params));
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Log Result
        </Typography>
        <LogResultSearch params={params} setParams={setParams} />
        <LogResultTable
          data={dataOcspConfig !== undefined ? dataOcspConfig?.data?.data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default LogResult;
