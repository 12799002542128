import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOcspById,
  removeEventFlag,
  getCertificateDropdown
} from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import adminManagement from "../../utils/validation/adminManagement";

const AdminManagementField = () => {

  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");
  const {
    editDataOcspConfig,
    isLoadingOcspConfig,
    isLoadingEvent,
    eventFlag
  } = useAppSelector((state: RootState)=> state.satpasDevice)
  const {
    uc_ocsp_config_uid,
   } = editDataOcspConfig
   const optStatusSelect = [
     {
       label: "Active",
       value: "Active",
     },
     {
       label: "Inactive",
       value: "Inactive",
     },
   ];
   const optRoleSelect = [
    {
      label: "SUPERADMIN",
      value: "SUPERADMIN",
    },
    {
      label: "ADMINISTRATOR",
      value: "ADMINISTRATOR",
    },
    {
      label: "OPERATOR",
      value: "OPERATOR",
    },
  ];

   
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    status: string;
    password: string;
    confirmPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminManagement),
    defaultValues: {
      uid: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "",
      status: "",
      password: "",
      confirmPassword: "",
    },
  });


  // REGION: SET FORM DATA
  useEffect(() => {
    if (editFgroup) {
      reset({
        uid: uc_ocsp_config_uid,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: "",
        status: "",
        password: "",
        confirmPassword: "",
      });
    } else {
      reset();
    }
  }, [ editDataOcspConfig ]);


  // REGION: DISPATCH GET DATA
  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getOcspById(idParam.id));
    }
    dispatch(getCertificateDropdown({}));
  }, []);


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      navigate("/admin-management")
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);


  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
    isLoading={isLoadingOcspConfig}
    isLoadingEvent={isLoadingEvent}
    inputCount={6}
    titleWidth={'350px'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {editFgroup ? "Update Admin Management" : "Create Admin Management"}
      </Typography>

      <InputForm
        name="firstName"
        label="Firstname"
        placeholder="Firstname"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={500}
        required
      />

      <InputForm
        name="lastName"
        label="Lastname"
        placeholder="Lastname"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={500}
        required
      />

      <InputForm
        name="email"
        label="Email"
        placeholder="Email"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={500}
        required
      />

      <InputForm
        name="phoneNumber"
        label="Phone Number"
        placeholder="Phone Number"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={500}
        required
        type="number"
      />

      <SelectFormm
        name="role"
        label="Role"
        defaultValue={0}
        options={optRoleSelect}
        placeholder="none"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
      />

      <SelectFormm
        name="status"
        label="Status"
        defaultValue={0}
        options={optStatusSelect}
        placeholder="none"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
      />

      { !editFgroup && <>
        <InputForm
          name="password"
          label="Password"
          placeholder="Password"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="password"
        />

        <InputForm
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm Password"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="password"
        />
      </> }

      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/admin-management")}>
          Cancel
        </Button>
        { editFgroup &&
          <Button variant="contained" type="submit">
            Update
          </Button>
        }
        { !editFgroup &&
          <Button variant="contained" type="submit">
            Submit
          </Button>
        }
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default AdminManagementField;
