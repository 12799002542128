import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import ImportKeyTable from '../../components/molecules/importKey/ImportKeyTable';
import { useGetImportKeyQuery } from '../../store/feature/service/importKeyApiSlice';
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const ImportKey = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // const data: any = []
  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data, isLoading } = useGetImportKeyQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    console.log('data ===> ', data)
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      // isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Import Public Key
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/import')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Import
        </Button>
        {/* <KeySearch params={params} setParams={setParams} /> */}
        <ImportKeyTable
          data={data !== undefined ? data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default ImportKey;
