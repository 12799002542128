// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const keyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getKey: builder.query<any, any>({
            query: params => {
                return {
                    url: `/key/user`,
                    params: params
                }
            },
            providesTags:['GetKeysList'],
        }),
        deleteKey: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/key/delete`,
                    method: 'DELETE',
                    body
                })
            },
            invalidatesTags:['DeleteKey', 'GetKeysList']
        })
  })
})

export const {
useGetKeyQuery,
useDeleteKeyMutation
} = keyApiSlice;