import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteKeyMutation } from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";
import { useEksportKeyDsaMutation, useEksportKeyRsaMutation } from "../../../store/feature/service/importKeyApiSlice";

const KeyTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [pemName, setPemName] = useState("");
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );
  const [deleteKey, { isSuccess, isError }] = useDeleteKeyMutation();

  const [
    eksportDsa,
    {
      isSuccess: successDsa,
      isError: isErrorDsa,
      data: dataDsa,
      error: errorDsa,
    },
  ] = useEksportKeyDsaMutation();
  const [
    eksportRsa,
    {
      isSuccess: successRsa,
      isError: isErrorRsa,
      data: dataRsa,
      error: errorRsa,
    },
  ] = useEksportKeyRsaMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/key/detail`, { state: params.row });
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteKey({
            kid: params.row.kid,
          });
        }
        console.log("kid ===> ", {
          kid: params.row.kid,
        });
      });
    };

    const onExport = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      if (params.row.keyType === "RSA") {
        eksportRsa({ kid: params.row.kid });
        const arrName = params.row.kid.split("/");
        setPemName(arrName[arrName.length - 1]);
        console.log("rusak RSA ==> ", arrName);
      } else {
        eksportDsa({ kid: params.row.kid });
        const arrName = params.row.kid.split("/");
        setPemName(arrName[arrName.length - 1]);
      }
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          {params.row.keyType === "RSA" ||
            params.row.keyType === "ECDSA" ? (
              <Button variant="contained" onClick={onExport}>
                Export
              </Button>
            ) : null }
          <Button variant="contained" onClick={onEdit}>
            Details
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "keyName",
      headerName: "Key Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "keyType",
      headerName: "Key Type",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "length",
      headerName: "Length",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data);
  const rows = data?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    keyName: row.sworldIdent,
    keyType: row.keyType,
    createdAt: row.created,
    length: row.length,
    kid: row.kid,
    appName: row.sworldAppname,
    curved: row.curve,
  }));

  if (isSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Deleted",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "key has been deleted",
    });
  }

  if (isError) {
    Swal.fire({
      icon: "error",
      title: "Error Delete",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "can not deleted Key",
    });
  }

  useEffect(() => {
    if (successDsa) {
      Swal.fire({
        icon: "success",
        title: "Export Public Key Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your ECDSA Public Key is being downloaded!",
      });
    }
  }, [successDsa]);

  useEffect(() => {
    if (successRsa) {
      Swal.fire({
        icon: "success",
        title: "Export Public Key Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your RSA Public Key is being downloaded!",
      });
    }
  }, [successRsa]);

  useEffect(() => {
    if (errorRsa) {
      const errSwal = errorRsa !== undefined ? errorRsa : 'error on converting ecdsa pem to jwk, details = WSOP Error:';
      Swal.fire({
        icon: "error",
        title: "Error Download Certificate",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "error on converting ecdsa pem to jwk, details = WSOP Error:",
      });
    }
  }, [isErrorDsa, isErrorRsa]);

  useEffect(() => {
    console.log("hahaha rsa ==> ", dataRsa);
    if (dataRsa) {
      const url = window.URL.createObjectURL(new Blob([dataRsa.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pemName+".pem");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [dataRsa]);

  useEffect(() => {
    if (dataDsa) {
      const url = window.URL.createObjectURL(new Blob([dataDsa.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pemName+".pem");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    console.log("hahaha dsa ==> ");
  }, [dataDsa]);

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      {/* <PaginationControl
        params={params}
        setParams={setParams}
        total={totalConfig}
      /> */}
    </>
  );
};

export default KeyTable;
